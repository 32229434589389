import React from "react";

import { OwcHyperlink, OwcIcon } from "@one/react";

function UnauthedWelcome() {
  return (
    <div className={"row"}>
      <h1>Welcome</h1>
      <p>To begin your Navify journey, please login with your Dialog account</p>
      <OwcHyperlink target={"_self"} href={"/oauth2/authorization/navify"}>
        Login With Dialog
        <OwcIcon slot="suffix" name="chevron_right" family="filled" />
      </OwcHyperlink>
    </div>
  );
}

export default UnauthedWelcome;
