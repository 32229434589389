import "./App.scss";

import React from "react";

import {
  OwcButton,
  OwcInput,
  OwcIcon,
  OwcAssistiveText,
  OwcForm,
  OwcHyperlink,
  OwcList,
  OwcListItem,
  OwcBanner,
} from "@one/react";

import { snackbarService } from "@one/web-components";

import axios from "axios";
import NavifyLogo from "./img/navify_Logo_1000px_White_RGB.svg";
import DialogLogo from "./img/logo_dialog.svg";
import Checkmark from "./img/checkmark.svg";
import UnauthedWelcome from "./UnauthedWelcome";

function getCookie(name) {
  var cookieValue;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

class App extends React.Component {
  logout = () => {
    const form = this.closest("form");
    form.submit();
  };

  tenantSelected = () => {};

  render() {
    return (
      <div className="App">
        <header className="App-header"></header>
        <div>
          <JoinTokenScreen />
          <br />
        </div>
      </div>
    );
  }
}

class JoinTokenScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userinfo: null,
      token: "",
      joinError: "",
      joinedTenant: {},
      joinSuccessComplete: false,
      knownRexisTenants: [],
      loading: true,
      valid_token: { state: "valid" },
    };
    this.portalLink = "https://esrv-hub-dev.roche.com";
    this.joinErrorMessages = {
      error: {
        "bad-token":
          "That seems to be an invalid or expired token, please try again",
      },
    };

    // init user
    this.getUserInfo();
  }

  getUserInfo() {
    axios
      .get("/navify/me")
      .then((rsp) => {
        this.setState({ userinfo: rsp.data, loading: false });
        // TODO: turn of a pol of the getUserInfo
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  startGettingUserInfo() {
    // TODO: start a timer trying to getUserInfo
  }

  clear() {
    this.setState({ userinfo: {} });
  }

  joinWithToken(joinReq) {
    let xsrfToken = getCookie("XSRF-TOKEN");
    let self = this;
    axios
      .post(
        "/api/join/tenant",
        {
          joinToken: joinReq.joinToken,
        },
        {
          headers: {
            "X-XSRF-TOKEN": xsrfToken,
          },
        }
      )
      .then(function (response) {
        const x = response.data;
        if (x.success) {
          self.setState({
            joinSuccessComplete: true,
            joinedTenant: x.tenant,
            joinError: "",
          });
          snackbarService.show({
            message: "Joined " + x.tenant.tenant_alias,
            type: "success",
            horizontalPlacement: "right",
            verticalPlacement: "bottom",
            duration: 5000, // you can give null or nothing for infinity time
          });
        } else {
          self.setState({
            joinErrorCode: "bad-token",
            joinError: "that seems to be an invalid token, please try again",
            joinSuccessComplete: false,
            valid_token: { state: "error" },
          });
          snackbarService.show({
            message: "That seems to be an invalid token, please try again",
            type: "error",
            horizontalPlacement: "right",
            verticalPlacement: "bottom",
            duration: 5000, // you can give null or nothing for infinity time
          });
        }
      })
      .catch((error) => {
        self.setState({
          joinErrorCode: "bad-token",
          joinError: "that seems to be an invalid token, please try again",
          joinSuccessComplete: false,
        });
      });
  }

  goToPortal(event) {
    // TODO: progress indicator
    window.location.assign(this.portalLink);
  }

  render() {
    if (this.state.userinfo) {
      return (
        <div className="user-info container">
          <div className="row">
            <div className={"col-md-4 col-12"}>
              <img
                src={DialogLogo}
                className={"dialog-hero-logo"}
                alt={"Roche Dialog"}
              />
            </div>
            <div className={"col-md-4 col-12"}>
              <h1 className={"welcome-header"}>Dialog Identity Verified </h1>
            </div>
            <div className={"col-md-4 col-12"}>
              <img src={Checkmark} className={"hero-icon"} alt={"success"} />
            </div>
          </div>
          <dl class="row">
            <dt className={"col-sm-3"}>Give Name</dt>
            <dd className={"col-sm-9"}>{this.state.userinfo.givenName}</dd>
            <dt className={"col-sm-3"}>Family Name</dt>
            <dd className={"col-sm-9"}>{this.state.userinfo.familyName}</dd>
            <dt className={"col-sm-3"}>Email</dt>
            <dd className={"col-sm-9"}>{this.state.userinfo.email}</dd>
            <dt className={"col-sm-3"}>Your New Navify User Id</dt>
            <dd className={"col-sm-9"}>{this.state.userinfo.subject}</dd>
          </dl>
          <div className={"row"}>
            <p>
              if this information is not correct, please contact{" "}
              <OwcHyperlink>Support</OwcHyperlink>{" "}
            </p>
            <h2>Join Your Tenant</h2>
            <div>
              {this.state.knownRexisTenants.length === 0 && (
                <div>
                  <p>
                    We're sorry, we can't find any tenants you're associated
                    with.
                  </p>
                  But you can still use a join code, or contact{" "}
                  <OwcHyperlink>Support</OwcHyperlink> for help
                </div>
              )}
              {this.state.knownRexisTenants.length >= 0 && (
                <OwcList onSelectChange={this.tenantSelected}>
                  {this.state.knownRexisTenants.map((z) => {
                    return <OwcListItem>{z.name}</OwcListItem>;
                  })}
                </OwcList>
              )}
            </div>
            <p>
              {!this.state.joinSuccessComplete && (
                <div>
                  <span>
                    Enter your <strong>Join Code</strong>
                  </span>
                  <OwcForm onFormSubmit={(ev) => this.joinWithToken(ev.detail)}>
                    <div className={"col-sm-12"}>
                      <OwcInput
                        label={"Join Code"}
                        placeholder={"Enter Your Tenant Join Code"}
                        className={"w-100"}
                        name={"joinToken"}
                      >
                        <OwcIcon
                          slot="prefix"
                          name="business"
                          family="filled"
                        />

                        <OwcIcon
                          slot="suffix"
                          name="chevron_right"
                          family="filled"
                        />

                        <OwcAssistiveText
                          trigger="input, blur"
                          messages={this.joinErrorMessages}
                          validity={this.state.valid_token}
                        >
                          You may have received a "Tenant Join Code" in an email
                          invitation. If so, please enter it here.
                        </OwcAssistiveText>
                      </OwcInput>
                      <OwcButton type={"submit"} className={"w-100"}>
                        <img
                          className={"navify-button-logo"}
                          src={NavifyLogo}
                          alt={"navify logo"}
                        />
                      </OwcButton>

                      {this.state.joinError !== "" && (
                        <div>
                          <OwcBanner
                            dismissible
                            type={"error"}
                            onDismiss={(ev) => this.setState({ joinError: "" })}
                          >
                            {this.state.joinError}
                          </OwcBanner>
                        </div>
                      )}
                    </div>
                  </OwcForm>
                </div>
              )}
              {this.state.joinSuccessComplete && (
                <div>
                  <OwcBanner type={"success"}>
                    Joined Tenant{" "}
                    <strong>{this.state.joinedTenant.tenant_alias}</strong>
                  </OwcBanner>
                  <br />
                  Great! You're ready to continue to the new Navify Portal
                  <OwcButton
                    onClick={(ev) => this.goToPortal(ev)}
                    className={"w-100"}
                  >
                    <img
                      className={"navify-button-logo"}
                      src={NavifyLogo}
                      alt={"navify logo"}
                    />
                  </OwcButton>
                </div>
              )}
            </p>
          </div>
          {!this.state.joinSuccessComplete && (
            <div className={"row"}>
              If you don't have a Navify Tenant, you can still skip to the
              <OwcHyperlink href={this.portalLink}>Navify Portal</OwcHyperlink>
            </div>
          )}
        </div>
      );
    } else {
      return <UnauthedWelcome />;
    }
  }
}

export default App;
