import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

import App from "./App";
import "./styles.scss";
import RocheLogo from "./img/roche_logo_white.svg";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OwcIconButton } from "@one/react";

function submitLogout(event) {
  document.getElementById("logout-form").submit();
}

function getCookie(name) {
  var cookieValue;
  if (document.cookie && document.cookie !== "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

let xsrfToken = getCookie("XSRF-TOKEN");

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <div className={"toolbar"}>
        <div className={"ms-3"}>
          <img src={RocheLogo} className={"toolbar-logo"} alt={"Roche Logo"} />
        </div>
        <div className={"spacer"}></div>
        <div className={"me-3"}>
          <form
            id="logout-form"
            action="/logout"
            method="POST"
            className={"float-end"}
          >
            <input type="hidden" name={"_csrf"} value={xsrfToken} />
            <OwcIconButton
              icon="log_off"
              type="legacy"
              onClick={submitLogout}
            />
            {/* <OwcButton type={"submit"} variant={"secondary"}>Logout</OwcButton> */}
          </form>
        </div>
      </div>
      <div></div>
      <Routes>
        <Route path="/" element={<App />}></Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
